import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom

const AdminTopMenu = () => {
    return (
  

            <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                <div className="container-fluid">
                    <button className="btn btn-primary" id="sidebarToggle">
                        Toggle Menu
                    </button>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                            <li className="nav-item active">
                                <a className="nav-link" href="#!">
                                   Logout
                                </a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </nav>

        



    );
};

export default AdminTopMenu;
