import React, { useState, useEffect } from 'react';
import AdminSideMenu from './AdminSideMenu';
import AdminTopMenu from './AdminTopMenu';


function DashboardContainer(props) {


    return (<>

        <div className="d-flex" id="wrapper">

            <AdminSideMenu></AdminSideMenu>

            <div id="page-content-wrapper" style={{width:'100%'}}>
                <AdminTopMenu></AdminTopMenu>

                <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">

                                


                            </div>
                        </div>
                </div>


            </div>


        </div>

    </>);

}

export default DashboardContainer;